<template>
    <b-row class="justify-content-center">
        <b-col md="6">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Organization Name"
                            label-for="organizations"
                            >
                            <b-form-input
                                id="organizations"
                                placeholder="Organization Name"
                                v-model="form.organizations"
                                required
                            />
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="addOrganization()"
                            >
                            Submit
                            </b-button>
                            <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            >
                            Reset
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                organizations: ''
            },
        }
    },
    methods: {
        addOrganization() {
            this.$http
                .post("organizations/add", {
                    "name": this.form.organizations
                })
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: 'Success add Organization',
                            variant: 'success',
                        },
                    })
                    location.href = "/settings/organizations"
                    // console.log(response.data.data)
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
        },
    },
}
</script>